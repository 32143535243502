import { Either } from "@dancrumb/fpish";
import { PublishedAppResponse, SutroError } from "sutro-common";
import { Product } from "sutro-common/sutro-data-store-types";
import { z } from "zod";
import { create } from "zustand";

import { Api } from "~/lib/sutro-api/Api";
import { SutroApi } from "~/lib/sutro-api/index";

import { StudioError } from "./studio-error";

/**
 * Converts a File object to a base64 encoded string.
 * @param {File} file - The file to be converted.
 * @returns {Promise<string>} A promise that resolves with the base64 encoded string.
 */
const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        // Remove the data URL prefix
        const base64 = reader.result.split(",")[1];
        resolve(base64);
      } else {
        reject(new StudioError("Failed to convert file to base64"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export enum Platform {
  WEB = "web",
  IOS = "ios",
  ANDROID = "android",
}

export enum PublishRequestStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  QUEUED = "QUEUED",
  FULFILLED = "FULFILLED",
}

export enum AppleTeamType {
  COMPANY_OR_ORGANIZATION = "COMPANY_OR_ORGANIZATION",
  INDIVIDUAL = "INDIVIDUAL",
}

export const appPublishSchema = z.object({
  appIconImage: z.object({
    image: z.string(),
    height: z.number(),
    width: z.number(),
  }),
  chosenPlatform: z.nativeEnum(Platform),
  expoOrganizationName: z.string().min(1, "Organization name is required"),
  expoPersonalAccessToken: z
    .string()
    .min(1, "Personal access token is required"),
  expoProjectId: z.string().min(1, "Expo project id is required"),
  expoProjectSlug: z.string().min(1, "Expo project slug is required"),
  privacyPolicyLink: z.string().url().optional(),
  termsOfServiceLink: z.string().url().optional(),
  splashScreenImage: z
    .object({
      image: z.string(),
      height: z.number(),
      width: z.number(),
    })
    .optional(),
  ascApiKeyFile: z
    .instanceof(File)
    .refine((file) => file.name.endsWith(".p8"), {
      message: "ASC API key file must be a .p8 file",
    }),
  ascKeyId: z.string().min(1, "Key identifier is required"),
  ascIssuerId: z.string().min(1, "Issuer identifier is required"),
  appleTeamId: z.string().min(1, "Apple Team ID is required"),
  appleTeamType: z.nativeEnum(AppleTeamType),
});

export type PublishFormType = z.infer<typeof appPublishSchema>;

interface PublishState {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  hasTermsOfServiceAndPrivacyPolicy: boolean;
  setHasTermsOfServiceAndPrivacyPolicy: (has: boolean) => void;
  hasPlaystoreDevAccount: boolean | undefined;
  setHasPlaystoreDevAccount: (has: boolean | undefined) => void;
  hasAppleDevAccount: boolean | undefined;
  setHasAppleDevAccount: (has: boolean | undefined) => void;
  executePublish: (
    formValues: PublishFormType & {
      productId: Product["id"];
    }
  ) => Promise<{
    data: PublishedAppResponse;
  }>;
  publishRequestStatus: PublishRequestStatus;
  publishRequestError: string | null;
  publishRequestResult: PublishedAppResponse | null;
  setPublishRequestStatus: (status: PublishRequestStatus) => void;
  setPublishRequestError: (error: string | null) => void;
  setPublishRequestResult: (result: PublishedAppResponse | null) => void;
  uploadAppIcon: (
    appIcon: PublishFormType["appIconImage"],
    productId: Product["id"]
  ) => void;
}

const initialState = {
  currentStep: 1,
  hasTermsOfServiceAndPrivacyPolicy: false,
  hasPlaystoreDevAccount: undefined,
  hasAppleDevAccount: undefined,
  authApi: SutroApi.getApi(),
  publishRequestError: null,
  publishRequestResult: null,
  publishRequestStatus: PublishRequestStatus.IDLE,
};

export const usePublish = create<
  PublishState & {
    authApi: Api;
  }
>((set, get) => ({
  ...initialState,
  setCurrentStep: (step: number) => set({ currentStep: step }),
  setHasTermsOfServiceAndPrivacyPolicy: (has: boolean) =>
    set({ hasTermsOfServiceAndPrivacyPolicy: has }),
  setHasPlaystoreDevAccount: (has: boolean | undefined) =>
    set({ hasPlaystoreDevAccount: has }),
  setHasAppleDevAccount: (has: boolean | undefined) =>
    set({ hasAppleDevAccount: has }),
  setPublishRequestStatus: (status: PublishRequestStatus) =>
    set({ publishRequestStatus: status }),
  setPublishRequestError: (error: string | null) =>
    set({ publishRequestError: error }),
  setPublishRequestResult: (result: PublishedAppResponse | null) =>
    set({ publishRequestResult: result }),
  uploadAppIcon: async (
    appIcon: PublishFormType["appIconImage"],
    productId: Product["id"]
  ) => {
    try {
      const imageBlob = await (await fetch(appIcon.image)).blob();

      const formData = new FormData();
      formData.append("appIcon", imageBlob, "app-icon.png");

      // TODO: SUT-3021 - this currently doesn't work. needs fixing
      return get().authApi.post(
        `/setAppIcon?productID=${productId}`,
        formData,
        {
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      );
    } catch (error) {
      return Either.left(error);
    }
  },
  /**
   * Initiates the app publishing process with validated form data.
   *
   * This function handles the entire publishing workflow for all platforms (iOS, Android, or Web).
   *
   * @param formValues - The validated form data containing all necessary information for publishing
   * @returns A Promise that resolves when the publishing process is complete
   * @throws Will throw an error if the publishing process fails
   */
  executePublish: async (
    formValues: PublishFormType & {
      productId: Product["id"];
    }
  ) => {
    get().setPublishRequestStatus(PublishRequestStatus.LOADING);

    const { appIconImage, splashScreenImage, ...formValuesWithoutImages } =
      formValues;

    const payloadToSend = formValuesWithoutImages;

    /**
     * Convert the ASC API key file to a base64 encoded string
     */
    if (formValues.chosenPlatform === Platform.IOS) {
      const ascApiKeyBase64 = await convertFileToBase64(
        formValues.ascApiKeyFile as File
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error this is typed as a File but we're sending a base64 string, which is intentional
      payloadToSend["ascApiKeyFile"] = ascApiKeyBase64;
    }

    /**
     * Upload the app icon to the server - this is required for both iOS and Android
     * TODO: Right now it doesn't do much error handling. If the upload fails, it'll probably use the default app icon.
     */
    if (
      formValues.chosenPlatform === Platform.IOS ||
      formValues.chosenPlatform === Platform.ANDROID
    ) {
      get().uploadAppIcon(appIconImage, formValues.productId);
    }

    const endpointMap = {
      [Platform.WEB]: "/publishMobileWeb",
      [Platform.IOS]: "/publishIOS",
      [Platform.ANDROID]: "/publishAndroid",
    };

    const endpoint = endpointMap[formValues.chosenPlatform];

    const publishRequestResponse = await get().authApi.post<{
      data: PublishedAppResponse;
    }>(endpoint, payloadToSend);

    return publishRequestResponse.map(
      () => {
        const errorMessage =
          "Something failed in the publish process. Please try again later.";
        get().setPublishRequestStatus(PublishRequestStatus.ERROR);
        get().setPublishRequestError(errorMessage);
        // eslint-disable-next-line sutro/errors-have-static-message
        throw new SutroError(errorMessage);
      },
      (data) => {
        get().setPublishRequestResult(data.data);
        get().setPublishRequestStatus(PublishRequestStatus.FULFILLED);
        return data;
      }
    );
  },
}));
