import { useFormContext } from "react-hook-form";
import { ButtonIds } from "sutro-analytics";
import { pick } from "sutro-common/collection-helpers/pick";
import { z } from "zod";
import { useShallow } from "zustand/react/shallow";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/components/ui/form";
import { appPublishSchema, usePublish } from "~/lib/use-publish";

import { Button } from "../../ui/button";
import { DialogFooter, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Switch } from "../../ui/switch";
import { ImageUpload } from "../image-upload";
import { DetailToggle } from "./detail-toggle";

const step4AndroidSchema = appPublishSchema.pick({
  termsOfServiceLink: true,
  privacyPolicyLink: true,
  splashScreenImage: true,
  appIconImage: true,
  expoProjectId: true,
  expoProjectSlug: true,
});

export function Step4Android({
  onBack,
  onContinue,
}: {
  onBack: () => void;
  onContinue: () => void;
}) {
  const { trigger, setValue, control, clearErrors } =
    useFormContext<z.infer<typeof step4AndroidSchema>>();

  const {
    hasTermsOfServiceAndPrivacyPolicy,
    setHasTermsOfServiceAndPrivacyPolicy,
  } = usePublish(
    useShallow(
      pick(
        "hasTermsOfServiceAndPrivacyPolicy",
        "setHasTermsOfServiceAndPrivacyPolicy"
      )
    )
  );

  const handleNextStep = async () => {
    const isValid = await trigger([
      "appIconImage",
      "expoProjectId",
      "expoProjectSlug",
      ...(hasTermsOfServiceAndPrivacyPolicy
        ? (["termsOfServiceLink", "privacyPolicyLink"] as const)
        : []),
    ]);

    if (isValid) {
      onContinue();
    }
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>Publish on Android</DialogTitle>
      </DialogHeader>
      <DetailToggle
        contentLeft={
          <div className="flex flex-col">
            <span className="text-sm font-medium">
              I have Terms and Conditions and a Privacy Policy
            </span>
            <span className="text-sm text-muted-foreground">Recommended</span>
          </div>
        }
        contentRight={
          <Switch
            checked={hasTermsOfServiceAndPrivacyPolicy}
            onCheckedChange={(checked) => {
              setHasTermsOfServiceAndPrivacyPolicy(checked);
              if (!checked) {
                (["termsOfServiceLink", "privacyPolicyLink"] as const).forEach(
                  (item) => {
                    setValue(item, "");
                    clearErrors(item);
                  }
                );
              }
            }}
          />
        }
        detailContent={
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium">
              Provide Terms and Conditions and a Privacy Policy
            </span>
            <p className="text-sm text-muted-foreground">
              It is optional for you to have Terms & Conditions and a Privacy
              Policy if you are the only person using your app. If anyone other
              than you will be using the app, you are required to provide Terms
              & Conditions and a Privacy Policy.
            </p>
            <FormField
              control={control}
              name="termsOfServiceLink"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>Terms and Conditions</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      error={fieldState.error?.message}
                      placeholder="e.g. https://docs.google.com/..."
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("termsOfServiceLink");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="privacyPolicyLink"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>Privacy Policy</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      error={fieldState.error?.message}
                      placeholder="e.g. https://docs.google.com/..."
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("privacyPolicyLink");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        }
        showDetail={hasTermsOfServiceAndPrivacyPolicy}
      />
      <DetailToggle
        contentLeft={
          <div className="flex flex-col">
            <span className="text-sm font-medium">Final steps</span>
          </div>
        }
        contentRight={<></>}
        detailContent={
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium">
              Complete these final steps to deploy your app
            </span>
            <p className="text-sm text-muted-foreground">
              It is optional for you to have Terms & Conditions and a Privacy
              Policy if you are the only person using your app. If anyone other
              than you will be using the app, you are required to provide Terms
              & Conditions and a Privacy Policy.
            </p>

            <FormField
              control={control}
              name="splashScreenImage"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>Splash screen image</FormLabel>
                  <FormControl>
                    <ImageUpload
                      currentImage={field.value}
                      onImageUpload={(newImage) => {
                        setValue("splashScreenImage", newImage);
                        clearErrors("splashScreenImage");
                      }}
                      notes="This image will be shown when the app is first launched"
                      error={fieldState.error?.message}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name="appIconImage"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>App icon</FormLabel>
                  <FormControl>
                    <ImageUpload
                      currentImage={field.value}
                      onImageUpload={(newImage) => {
                        if (newImage !== undefined) {
                          setValue("appIconImage", newImage);
                          clearErrors("appIconImage");
                        }
                      }}
                      error={fieldState.error?.message}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name="expoProjectId"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>Expo project ID</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      error={fieldState.error?.message}
                      placeholder="e.g. MyApp..."
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("expoProjectId");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name="expoProjectSlug"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>Expo project slug</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      error={fieldState.error?.message}
                      placeholder="e.g. my-app..."
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("expoProjectSlug");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        }
        showDetail={true}
      />
      <DialogFooter>
        <Button
          variant="secondary"
          onClick={onBack}
          testId={ButtonIds.PUBLISH_FLOW_PREVIOUS_STEP}
        >
          Back
        </Button>
        <Button
          onClick={handleNextStep}
          testId={ButtonIds.PUBLISH_FLOW_FINISH_PUBLISH}
        >
          Continue
        </Button>
      </DialogFooter>
    </>
  );
}
