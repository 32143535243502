import { ArrowRight, Rocket } from "lucide-react";
import { pick } from "sutro-common/collection-helpers/pick";
import { useShallow } from "zustand/react/shallow";

import { usePublish } from "~/lib/use-publish";

import { Button } from "../../ui/button";

export function Step3Web() {
  const { publishRequestResult } = usePublish(
    useShallow(pick("publishRequestResult"))
  );

  return (
    <div className="flex flex-col items-center py-6">
      <Rocket className="size-12 text-primary" />
      <span className="mt-4 text-2xl font-semibold">Congratulations!</span>
      <span className="mt-2">Your web app has been published!</span>
      <Button className="mt-6" asChild>
        <a
          href={publishRequestResult?.futureWebAppURL}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="flex items-center gap-2">
            <span>Visit app</span>
            <ArrowRight className="size-4" />
          </div>
        </a>
      </Button>
      <a
        className="mt-4 flex items-center gap-2 text-sm"
        href="https://www.withsutro.com/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <span>
          Check out the Sutro blog for tips on how to grow and manage your
          product
        </span>
        <ArrowRight className="size-4" />
      </a>
    </div>
  );
}
