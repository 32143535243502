import * as RadioGroup from "@radix-ui/react-radio-group";
import { ArrowUpCircle } from "lucide-react";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ButtonIds, StudioEventTypes } from "sutro-analytics";
import { PostHogFlags } from "sutro-common/feature-flags/posthog-flags";
import { isTierUpgrade } from "sutro-common/studio-entitlements";
import { z } from "zod";

import { Button } from "~/components/ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "~/components/ui/dialog";
import { PremiumWrapper } from "~/components/ui/premium-wrapper";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useAnalytics } from "~/lib/use-analytics";
import { appPublishSchema, Platform } from "~/lib/use-publish";
import { cn } from "~/lib/utils";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

import { PlatformAndroidIcon } from "./platform-android-icon";
import { PlatformIosIcon } from "./platform-ios-icon";
import { PlatformRadioItem } from "./platform-radio-item";
import { PlatformWebIcon } from "./platform-web-icon";

const step1Schema = appPublishSchema.pick({
  chosenPlatform: true,
});

function UpgradeButton({
  onRequestUpgrade,
  message,
}: {
  onRequestUpgrade: () => void;
  message: string;
}) {
  return (
    <Button
      className="flex flex-row justify-between gap-2 border border-warning-strong bg-warning-strong px-4 py-2 text-black"
      onClick={onRequestUpgrade}
    >
      <ArrowUpCircle size={20} />
      <div className="w-min">{message}</div>
    </Button>
  );
}

function FreeTierWarning({
  onRequestUpgrade,
}: {
  onRequestUpgrade: () => void;
}) {
  return (
    <div className="flex flex-row content-center justify-between gap-x-1 rounded-md border border-border-warning-muted bg-bg-warning-muted py-4 pl-4 pr-6">
      <div className="flex flex-col gap-1 text-warning">
        <div className="text-sm font-semibold leading-5">
          Upgrade now to publish your app!
        </div>
        <div className="text-xs font-normal leading-4">
          You&apos;re currently on the Free plan
        </div>
      </div>
      <UpgradeButton
        onRequestUpgrade={onRequestUpgrade}
        message="Upgrade now"
      />
    </div>
  );
}

export function Step1({
  onContinue,
  onCancel,
  onRequestUpgrade,
}: {
  onContinue: () => void;
  onCancel: () => void;
  onRequestUpgrade: () => void;
}) {
  const { entitlements, currentSubscription } = useEntitlements();
  const flags = useFeatureFlags(PostHogFlags.PremiumFeatures);
  const canPublish =
    !flags[PostHogFlags.PremiumFeatures] ||
    entitlements.some((e) => e.entitlementKey === "publish-app");
  const { setValue, watch } = useFormContext<z.infer<typeof step1Schema>>();
  const chosenPlatform = watch("chosenPlatform");
  const { track } = useAnalytics();

  // Pre-select the web platform if the user can publish
  useEffect(() => {
    if (
      chosenPlatform === undefined &&
      currentSubscription?.tier.name !== "Free" &&
      canPublish
    ) {
      setValue("chosenPlatform", Platform.WEB);
    }
  }, [canPublish, currentSubscription]);

  const handleNextStep = useCallback(() => {
    track(StudioEventTypes.BUTTON_CLICK, {
      buttonId: ButtonIds.PUBLISH_FLOW_CHOOSE_PLATFORM,
      chosenPlatform,
    });
    onContinue();
  }, [chosenPlatform, onContinue, track]);

  const showUpgradeButton =
    !canPublish && isTierUpgrade(currentSubscription.tier.name, "Pro");
  const showUpgradeWarning =
    !canPublish && currentSubscription.tier.name === "Free";

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <DialogTitle>Publish app</DialogTitle>
        <DialogDescription>
          Which platform do you want to publish your app on? To publish your app
          on more than one platform, simply complete the process for one
          platform and click “Publish” in the studio to begin the process again
          for another platform.
        </DialogDescription>
      </div>
      {showUpgradeWarning && (
        <FreeTierWarning onRequestUpgrade={onRequestUpgrade} />
      )}

      <RadioGroup.Root
        value={chosenPlatform}
        onValueChange={(platform) => {
          setValue("chosenPlatform", platform as Platform);
        }}
        className="flex flex-col gap-2"
      >
        <PremiumWrapper tier="Starter" entitlementKey="publish-to-mobile-web">
          <PlatformRadioItem
            platform={Platform.WEB}
            icon={<PlatformWebIcon />}
            title="Web"
            description="Recommended"
          />
        </PremiumWrapper>
        <PremiumWrapper tier="Pro" entitlementKey="publish-to-app-store">
          <PlatformRadioItem
            platform={Platform.IOS}
            icon={<PlatformIosIcon />}
            title="iOS"
            description=""
          />
        </PremiumWrapper>
        <PremiumWrapper tier="Pro" entitlementKey="publish-to-google-play">
          <PlatformRadioItem
            platform={Platform.ANDROID}
            icon={<PlatformAndroidIcon />}
            title="Android"
            description=""
          />
        </PremiumWrapper>
      </RadioGroup.Root>
      <DialogFooter className="w-full">
        <div
          className={cn({
            "flex w-full flex-row justify-between": showUpgradeButton,
          })}
        >
          {showUpgradeButton && (
            <div className="flex grow flex-row justify-start">
              <UpgradeButton
                onRequestUpgrade={onRequestUpgrade}
                message="Upgrade to Pro"
              />
            </div>
          )}
          <div className="flex flex-row gap-2">
            <Button
              variant="secondary"
              onClick={onCancel}
              testId={ButtonIds.PUBLISH_FLOW_CANCEL}
            >
              Cancel
            </Button>
            <Button
              disabled={!chosenPlatform}
              onClick={handleNextStep}
              type="button"
              testId={ButtonIds.PUBLISH_FLOW_NEXT_STEP}
            >
              Continue
            </Button>
          </div>
        </div>
      </DialogFooter>
    </div>
  );
}
