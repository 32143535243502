import * as RadioGroup from "@radix-ui/react-radio-group";
import { Check } from "lucide-react";

import { Platform } from "~/lib/use-publish";

export function PlatformRadioItem({
  platform,
  icon,
  title,
  description,
}: {
  platform: Platform;
  icon: React.ReactNode;
  title: string;
  description: string;
}) {
  return (
    <RadioGroup.Item
      value={platform}
      className="group flex w-full items-center rounded border px-6 py-4 data-[state=checked]:border-primary"
    >
      <div className="text-muted-foreground group-data-[state=checked]:text-primary">
        {icon}
      </div>
      <div className="ml-4 flex grow flex-col items-start">
        <span className="text-lg font-semibold text-muted-foreground group-data-[state=checked]:text-foreground">
          {title}
        </span>
        <span className="text-sm text-muted-foreground">{description}</span>
      </div>
      <RadioGroup.Indicator asChild>
        <Check className="size-6 text-primary" />
      </RadioGroup.Indicator>
    </RadioGroup.Item>
  );
}
